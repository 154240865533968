<script lang="ts">
  import { onMount } from 'svelte'
  import { type Writable, writable, get } from 'svelte/store'

  import { state } from '../../../store/state'
  import { selectPhrase } from '../../../utils'

  import ViewToolbarButtons from '../../atoms/view-toolbar-buttons.svelte'

  import ContentPresenter from '../presenter.svelte'
  import { Presenter, toggle } from '../presenter'

  import ContentPreview from '../../content-view/content-preview.svelte'

  import DataRenderer from './main-data-renderer.svelte'

  export let contentKindName = 'articles'

  let showCardView = writable(false)
  let showPreview = writable(true)

  const contentKind = state[contentKindName]
  const { data } = contentKind
  const toggles = { showCardView, showPreview }
  const subscriptions: (() => void)[] = []

  let content: any
  let presenter: Presenter
  let routeParams = writable({} as any)
  let presenterUnsubscribe: () => void
  let deletedUnsubscribe: () => void

  const handleCardView = (view: 'grid' | 'list') => {
    const isCardView = view === 'grid'
    if ($showCardView !== isCardView) {
      toggle('showCardView', toggles)
    }
  }

  const setContent = () => {
    if (!presenter) {
      return
    }
    const id = presenter.selectedContentId.get()
    const newContent = data.get().find((c: any) => {
      return c.id === id
    })

    if (newContent) {
      content = { ...newContent }
    }
  }

  subscriptions.push(
    data.subscribe(() => {
      setContent()
    })
  )

  subscriptions.push(
    routeParams.subscribe((params) => {
      Object.keys(toggles).forEach((key) => {
        const toggle: Writable<boolean> = (toggles as any)[key]
        const external = params[key]
        if (external === undefined) {
          return
        }

        if (external.toString() !== get(toggle).toString()) {
          const booleanValue = external === 'true'
          toggle.set(booleanValue)
        }
      })
    })
  )

  $: {
    if (presenter) {
      if (presenterUnsubscribe) {
        presenterUnsubscribe()
      }

      if (deletedUnsubscribe) {
        deletedUnsubscribe()
      }

      presenterUnsubscribe = presenter.selectedContentId.listen(() => {
        setContent()
      })

      deletedUnsubscribe = presenter.virtualData.subscribe((actions) => {
        const selectedId = presenter.selectedContentId.get()
        const selectedContentActions = actions.filter((action: any) => {
          return action.rowStatus === 'deleted' && action.id === selectedId
        })
        if (selectedContentActions.length) {
          presenter.selectedContentId.set('')
          content = undefined
        }
      })
    }
  }

  onMount(() => {
    return () => {
      subscriptions.forEach((sub) => sub())
      if (presenterUnsubscribe) {
        presenterUnsubscribe()
      }
      if (deletedUnsubscribe) {
        deletedUnsubscribe()
      }
    }
  })
</script>

<div class="content-presenter hide-columns w-full h-full">
  <ContentPresenter
    {contentKindName}
    bind:presenter
    {DataRenderer}
    {routeParams}
    {showCardView}
    {showPreview}
  >
    <div slot="toolbar-card-view">
      <ViewToolbarButtons
        showCardView={$showCardView}
        showPreview={$showPreview}
        onToggleCardView={handleCardView}
        onTogglePreview={() => toggle('showPreview', toggles)}
      />
    </div>
    <ContentPreview
      slot="right-panel"
      bind:content
      {contentKind}
      highlightFields={['id', ...contentKind.kind.ftsFields]}
      noContentText={selectPhrase(contentKind.kind.type)}
      bind:showPreview={$showPreview}
    />
  </ContentPresenter>
</div>

<style lang="postcss">
  /* hiding byline when < 60rem */
  @media (max-width: 60rem) {
    .hide-columns :global(thead > tr > th:nth-child(5)),
    .hide-columns :global(tbody > tr > td:nth-child(5)) {
      display: none;
    }
  }
  .content-presenter :global(.bx--btn--primary:focus) {
    box-shadow: unset;
    border-color: transparent;
  }
</style>
