<script lang="ts">
  import { getContext } from 'svelte'

  import ContentDataRenderer from '../content-data-renderer/content-data-renderer.svelte'
  import { Presenter } from '../presenter'

  import CellRenderer from './main-cell-renderer.svelte'
  import CardContainer from './main-card-container.svelte'

  export let containerHeight: number

  const { showCardView, showPreview, contentKind } = getContext(Presenter.key) as any
</script>

<ContentDataRenderer
  cellRenderer={CellRenderer}
  cardContainer={CardContainer}
  {contentKind}
  {containerHeight}
  {showCardView}
  {showPreview}
/>
